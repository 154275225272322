export default [
  {
    header: 'Commerciaux',
    icon: 'HomeIcon',
    image: 'team.png',
    children: [
      {
        title: 'Liste des commerciaux',
        route: 'commerciaux-liste',
        icon: 'ListIcon',
      },
      {
        title: 'Les objectifs distributeurs',
        route: 'commerciaux-objectifs-distributeurs',
        icon: 'TargetIcon',
      },
      // {
      //   title: 'Les statistiques commerciaux',
      //   route: 'commerciaux-statistiques',
      //   icon: 'BarChart2Icon',
      // },
    ],
  },
]
